var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',[_c('SearchField',{staticClass:"font-weight-black",model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('BtnAdd',{attrs:{"to":"/pendiente/add"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.getPendiente,"loading":_vm.isLoading,"mobile-breakpoint":0,"items-per-page":99999,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.descripcion))])]}},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.activo ? 'red' : 'green accent-3'}})]}},{key:"item.idtipo_pendiente.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.idtipo_pendiente.descripcion))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","x-small":"","text":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.setData(item)}}},[_c('c-icon',[_vm._v(" arrow_forward_ios ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }